import React from "react"
import { Button, Layout } from 'antd'
import SiteLayout from "../components/SiteLayout";

import { Collapse } from 'antd';
const Panel = Collapse.Panel;

// @ts-ignore
import styles from './brukervilkar.module.scss';

export default class Vilkar extends React.Component<any, any> {

    render() {
        return (
            <SiteLayout>
    <div className={styles.smallcontainer}>
                <h1>Brukervilkår</h1>

                <h2>
                    Veldig gøy at du vil bli en av Oss.
                </h2>
                <p>
                    Før du kommer skikkelig i gang med tjenesten som gir innsikt i ditt strømforbruk, oppfordrer vi deg til å lese
                    igjennom avtalen mellom oss. Dersom du lurer på noe eller opplever at noe er uklart, ta kontakt med oss på
                    hei@oss.no, så hjelper vi deg selvfølgelig med det.
                </p>


                <ol>
                    <li>Innledning
                        <ol>
                            <li>
                                Denne avtalen (“Avtalen” eller "Brukervilkårene") regulerer bruk av Oss-Appen (“Oss” eller
                                “Appen”). Partene i Avtalen er deg som bruker av Oss ("Bruker", “du” eller “deg”) og Oss Norge AS
                                med organisasjonsnummer 919 439 734 (“Selskapet”, “vi” “våre” eller “oss”).
                            </li>
                            <li>
                                Du inngår denne Avtalen med oss ved å opprette en brukerprofil i Appen og godta disse
                                Brukervilkårene.
                            </li>
                            <li>
                                Disse Brukervilkårene kan endres. Se punkt 7 for mer informasjon.
                            </li>

                        </ol>
                    </li>
                    <li>Om Oss
                        <ol>
                            <li>
                                Oss har utviklet en helt ny teknologi som gir forbrukerne tilgang til verdifull data om
                                strømforbruket i hjemmet. Ved hjelp av Oss-Brikken hjelper vi deg med å hente ut denne
                                informasjonen fra de nye automatiske strømmålerne, og med Oss-Appen tolker vi informasjonen for deg
                                slik at det blir enkelt å forstå det historiske strømforbruket og hvor mye du bruker akkurat nå.
                                Men, det finnes også mange andre spennende muligheter – og det er disse vi har lyst til å utvikle
                                videre i samarbeid med Brukerne våre. Les mer på www.oss.no.
                            </li>
                            <li>
                                Teknologien til Oss består av en komponent som vi kaller Oss-Brikken som kommuniserer direkte
                                med plattformen vår ved hjelp av IoT kommunikasjonsteknologi over 4G-nettet. Oss-Brikken kan du
                                plugge direkte inn i den nye automatiske strømmåleren din. Når du gjør dette får du tilgang til den
                                samme dataen som måleren sender til de som eier strømnettet i området du bor i, men mye mer
                                detaljert. Dette er data som viser hvor mye strøm du bruker, og når du bruker den. Les mer på
                                www.oss.no.
                            </li>
                        </ol>
                    </li>
                    <li>Retningslinjer for bruk av Oss
                        <ol>
                            <li>For å opprette en profil knyttet til et målernummer må man ha fylt 18 år.
                            </li>
                            <li>Kun registrert eier av strømmåleren kan knytte måleren til sin profil. Registrert eier er den
                                som står oppført og/eller mottar faktura fra netteier. Du plikter å oppgi korrekt informasjon om
                                deg selv, og du kan ikke utgi deg for å være noen andre når du oppretter profil i Oss.

                                Når du oppretter profil i Oss ber vi deg registrere epost. Denne bruker vi for å sende deg
                                påloggingslink dersom du må logge inn på nytt (for eksempel dersom du har fått en ny telefon, eller
                                dersom du har logget ut av Appen). Du kan logge inn enten ved å trykke på linken eller taste inn
                                koden oppgitt i eposten.

                            </li>
                            <li>Installering av Oss-Brikken som du får fra oss kan bare skje i den strømmåleren (husstanden)
                                som du er registrert eier for i overensstemmelse med punkt 3.2. Du kan ikke installere din
                                Oss-Brikke i andre strømmålere, husstander eller enheter.
                            </li>
                            <li>Det er ikke tillatt å åpne eller endre Oss-Brikken for å gjøre egne konfigurasjoner. Dersom du
                                åpner Oss-Brikken og/eller gjør endringer forbeholder vi oss retten til å sperre din konto.
                            </li>
                            <li>Informasjonen fra strømmåleren er dine personopplysninger, øvrige rettigheter og alt materiale
                                vedrørende Oss, herunder varemerker, programkode, tekst, bilder og lignende tilhører Selskapet.
                            </li>
                            <li>Om du ønsker å benytte slike rettigheter eller materiale som tilhører Selskapet som nevnt i
                                punkt 3.5, ut over det som fremgår av Avtalen, må du på forhånd få vår skriftlige godkjennelse på
                                forhånd.

                            </li>
                            <li>Oss har flere samarbeidspartnere som kan tilby tjenester basert på Brukers strømdata i sine brukerflater.  
                                Bruker samtykker til at Oss deler personopplysninger med involverte samarbeidspartnere for slikt formål og på slikt grunnlag. 
                                Samtykke kan når som helst trekkes tilbake. 
                                Vern om personopplysninger ivaretas gjennom inngåelse av databehandleravtale med involverte samarbeidspartnere som pålegges samme forpliktelser som gjelder for Oss.
                            </li>
                        </ol>
                    </li>
                    <li>Tilgjengelighet og ansvar
                        <ol>
                            <li>
                                Selv om vi gjør vårt beste for at Oss skal være tilgjengelig når du vil bruke tjenesten, kan
                                det fra tid til annen hende at Oss er helt eller delvis utilgjengelig, enten på grunn av
                                vedlikehold, feil eller annet. Dette kan i verste fall føre til at overføring av data ikke blir
                                gjennomført. Vi vil forsøke å gi informasjon om eventuelle begrensninger i tilgjengeligheten, men
                                kan ikke utelukke at Oss ikke alltid kan benyttes som forutsatt. Selskapet er ikke ansvarlig for
                                noen direkte eller indirekte følger av begrenset tilgjengelighet eller feil i tjenesten, verken
                                økonomisk eller på annen måte.
                            </li>
                        </ol>
                    </li>

                    <li>Diverse
                        <ol>
                            <li>Avtalen er underlagt norsk rett. Eventuelle tvister som ikke løses i minnelighet avgjøres av
                                norske domstoler. </li>
                        </ol>
                    </li>
                    <li>Kontakt og kommunikasjon
                        <ol>
                            <li>
                                Vi kan kommunisere med deg gjennom Appen, varslinger, sms og epost. Vi sender deg bare
                                markedsføringshenvendelser dersom du har samtykket til dette.
                            </li>
                        </ol>
                    </li>

                    <li>Endringer i avtalen
                        <ol>
                            <li>Vi ønsker å tilby en tjeneste som er best mulig og tilpasset brukernes behov. Oss er derfor i
                                stadig utvikling både når det gjelder utseende, funksjonalitet og innhold. Vi vil passe på å holde
                                deg informert om endringer og nye muligheter i Appen.
                            </li>
                            <li>Den til enhver tid gjeldende Avtale vil alltid være tilgjengelig i Appen og på vår hjemmeside.
                            </li>
                            <li>Dersom vi gjør endringer som åpenbart er til Brukers fordel informerer vi om dette når
                                endringen trer i kraft. For andre endringer vil vi gi beskjed 14 dager før endringen trer i kraft, 
                                slik at du kan bestemme deg for om du vil fortsette å bruke Oss på de nye vilkårene.
                            </li>
                            <li>Dersom du ikke aksepterer nye og/eller endrede vilkår i denne Avtalen, kan du si opp Avtalen i
                                overensstemmelse med punkt 8.3. Du får da ikke tilgang til tjenesten og Oss-Brikken vil bli
                                sperret.
                            </li>
                        </ol>
                    </li>
                    <li>Varighet og oppsigelse
                        <ol>
                            <li>Vi kan si opp Avtalen med umiddelbar virkning dersom du handler i strid med bestemmelser i Avtalen eller på annen måte misbruker Oss. 
                                Eventuelle abonnement du har hos oss knyttet til Appen og/eller Oss-brikken vil bli kansellert og tilgang til tjenestene opphører. 
                            </li>
                        </ol>
                    </li>
                    <li>Informasjonssikkerhet
                        <ol>
                            Vi tar informasjonssikkerhet på alvor, og har etablerte og dokumenterte rutiner og tiltak som skal
                            sikre personopplysningers riktighet, integritet, og konfidensialitet i henhold til kravene i
                            regelverket om personopplysninger. Se vår personvernerklæring for mer informasjon om hvordan vi
                            behandler dine personopplysninger.
                        </ol>
                    </li>
                </ol>
            </div>
            </SiteLayout>
        );
    }
}